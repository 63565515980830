import type { FC } from "react"
import React from "react"
import _t from "@core/i18n"
import { PriceType } from "@core/types"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import ToggleWithLabelsAtm from "@onestore/hel/dist/components/atoms/ToggleWithLabelsAtm"
import WrapOrg from "@onestore/hel/dist/components/organisms/WrapOrg"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import { productBoxClickEvent } from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"

type Props = {
  text?: string
}

const TogglePriceType: FC<Props> = ({ text }: Props) => {
  const { currentPriceType, isNettoPriceType, setPriceType } =
    usePriceTypeContext()

  const handleToggleSwitch = () => {
    let eventPriceType = PriceType.GROSS

    if (currentPriceType === PriceType.NETTO) {
      setPriceType(PriceType.GROSS)
    } else {
      setPriceType(PriceType.NETTO)
      eventPriceType = PriceType.NETTO
    }

    productBoxClickEvent({
      productbox_type: "toggle",
      productbox_option: eventPriceType,
    })
  }

  return (
    <WrapOrg gutterSpace={2} alignCenter="vertical">
      {!isEmpty(text) ? <TextAtm typography="nano1">{text}</TextAtm> : null}

      <ToggleWithLabelsAtm
        id="toggle-price-type"
        values={[_t("prices.netto"), _t("prices.gross")]}
        activeIndex={isNettoPriceType ? 0 : 1}
        onClick={handleToggleSwitch}
      />
    </WrapOrg>
  )
}

export default TogglePriceType
