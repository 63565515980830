import type { ReactElement } from "react"
import React from "react"
import ConfigBoxMol from "@onestore/hel/dist/components/molecules/ConfigBoxMol"
import type { PeriodsTableSelect } from "@gatsby-plugin-generic-page/fragments/periodsTableSelect"
import getPeriodsByPeriodVariants from "@gatsby-plugin-generic-page/helpers/getPeriodsByPeriodVariants"
import type { ConfigsHookResult } from "@gatsby-plugin-generic-page/hooks/useTableConfigs"
import isEmpty from "~/lib/isEmpty"
import SelectConfigElement from "./SelectConfigElement"

type PeriodsConfigBoxProps = {
  configLabel: string
  configText?: string
  periodsData: PeriodsTableSelect["periods"]
  currentPeriod: string
  onChange: ConfigsHookResult["handlePeriodChange"]
}

export default function PeriodsConfigBox({
  configLabel,
  configText,
  periodsData,
  currentPeriod,
  onChange,
}: PeriodsConfigBoxProps): ReactElement<PeriodsConfigBoxProps> {
  const periods = periodsData.map((period) => ({
    value: period.value.period,
  }))

  const periodVariants = getPeriodsByPeriodVariants(periods)

  if (isEmpty(periodVariants)) {
    return <></>
  }

  const selectOptions = Object.values(periodVariants).map((variant) => ({
    label: variant.title,
    value: variant.period,
    isSelected: variant.period === currentPeriod,
  }))

  const selectConfigElement = (
    <SelectConfigElement
      options={selectOptions}
      onChange={(value) => onChange(value)}
    />
  )

  return (
    <ConfigBoxMol
      label={configLabel}
      text={configText}
      configElement={selectConfigElement}
      hasSelect
    />
  )
}
