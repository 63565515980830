import type { ReactElement } from "react"
import React from "react"
import type { MarkdownToJSX } from "markdown-to-jsx"
import type { TypographyBreakpoint } from "@onestore/hel/dist/components/atoms/TextAtm/TextAtm.props"
import UnderlineLinkAtm from "@onestore/hel/dist/components/atoms/UnderlineLinkAtm"
import type Theme from "@onestore/hel/dist/typings/theme"
import getBaseMarkdownOverrides from "@gatsby-plugin-generic-page/helpers/getBaseMarkdownOverrides"
import type { SquidexMarkdownEmbeddedContent } from "~/types/squidex"
import BaseMarkdown from "../Base"
import { getRegExpBySchemaId } from "./contentType"
import EmbeddedContent from "./EmbeddedContent"

interface Props {
  children: string | SquidexMarkdownEmbeddedContent
  typography?: { bold: Theme.Typography; default: Theme.Typography }
  emphasis?: Theme.Emphasis
  typographyBreakpoint?: {
    default: TypographyBreakpoint
    bold: TypographyBreakpoint
  }
  overrides?: MarkdownToJSX.Overrides
  extraOverrides?: MarkdownToJSX.Overrides
  forceBlock?: boolean
  forceWrapper?: boolean
  wrapper?: React.ElementType | null
  pushSpace?: number
  hasOverridedSmallTypography?: boolean
}

function Text({
  children: componentChildren,
  typography,
  emphasis,
  overrides,
  extraOverrides,
  typographyBreakpoint,
  forceWrapper = false,
  forceBlock = true,
  wrapper,
  pushSpace,
  hasOverridedSmallTypography,
}: Props): ReactElement<Props> {
  const baseMarkdownOverrides = getBaseMarkdownOverrides({
    typography: typographyBreakpoint?.default || {
      small: hasOverridedSmallTypography
        ? typography?.default || "small1"
        : "small1",
      medium: typography?.default || "medium1_h",
      large: typography?.default || "medium1_h",
    },
    boldTypography: typographyBreakpoint?.bold || {
      small: hasOverridedSmallTypography
        ? typography?.bold || "small4"
        : "small4",
      medium: typography?.bold || "medium3_h",
      large: typography?.bold || "medium3_h",
    },
    pushSpace: pushSpace ?? 1,
    emphasis,
  })

  const hasStringChildrenType = typeof componentChildren === "string"
  const text = hasStringChildrenType
    ? componentChildren
    : componentChildren.text

  return (
    <BaseMarkdown
      options={{
        overrides: {
          ...(overrides || baseMarkdownOverrides),
          ...extraOverrides,
          a: {
            component: ({ href, children, title }) => {
              const regex = getRegExpBySchemaId(
                "video|cta-element|counter|marketplace-sale-configuration|static-price|cloudblue-domain"
              )
              const match = regex.exec(href)

              if (match && match.groups && !hasStringChildrenType) {
                const referenceId = match.groups.id

                return (
                  <EmbeddedContent
                    referenceId={referenceId}
                    typography={typography?.default}
                    {...componentChildren}
                  />
                )
              }

              return (
                <UnderlineLinkAtm
                  typography={typography?.default || "medium1_h"}
                  href={href}
                  title={title || children}
                  emphasis={emphasis}
                >
                  {children}
                </UnderlineLinkAtm>
              )
            },
          },
        },
        wrapper,
        forceWrapper,
        forceBlock,
      }}
    >
      {text}
    </BaseMarkdown>
  )
}

export default Text
