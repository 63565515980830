import type { Context, ReactElement, ReactNode } from "react"
import { useContext } from "react"
import React from "react"
import { createContext, useState } from "react"
import { hasNoTaxRate } from "@core/pricing"
import { PriceType } from "@core/types"
import { useLocation } from "@reach/router"
import { useIsomorphicLayoutEffect } from "@gatsby-plugin-generic-page/hooks/useIsomorphicLayoutEffect"
import useSiteMetadataQuery from "~/hooks/useSiteMetadataQuery"
import isEmpty from "~/lib/isEmpty"
import Storage from "~/lib/storage"

type PriceTypeProps = {
  currentPriceType: PriceType
  isNettoPriceType: boolean
  setPriceType: { (priceType: PriceType): void }
}

const priceTypeContextDefault: PriceTypeProps = {
  currentPriceType: hasNoTaxRate() ? PriceType.NETTO : PriceType.GROSS,
  isNettoPriceType: hasNoTaxRate(),
  setPriceType: () => null,
}

export const PriceTypeContext = createContext<PriceTypeProps>(
  priceTypeContextDefault
)

export const usePriceTypeContext = () => useContext(PriceTypeContext)

export const isPriceType = (value: string): value is PriceType => {
  return Object.values(PriceType).includes(value as PriceType)
}

type PriceTypeContextProviderProps = {
  children: ReactNode
  isMarketplace?: boolean
}

export function PriceTypeContextProvider({
  children,
  isMarketplace,
}: PriceTypeContextProviderProps): ReactElement<Context<PriceTypeProps>> {
  const { defaultPriceType, defaultMarketplacePriceType } =
    useSiteMetadataQuery()

  const updatedDefaultPriceType = isMarketplace
    ? defaultMarketplacePriceType
    : defaultPriceType

  const [currentPriceType, setCurrentPriceType] = useState(
    updatedDefaultPriceType
  )

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const priceParam = queryParams.get("priceType")

  const isNettoPriceType =
    currentPriceType === PriceType.NETTO || hasNoTaxRate()

  const setPriceType = (priceType: PriceType): void => {
    const finalPriceType = hasNoTaxRate() ? PriceType.NETTO : priceType

    Storage.setPriceType(finalPriceType, isMarketplace)

    setCurrentPriceType(finalPriceType)
  }

  useIsomorphicLayoutEffect()(() => {
    if (priceParam && isPriceType(priceParam)) {
      setPriceType(priceParam)

      return
    }

    const storagePriceType = Storage.getPriceType(isMarketplace)

    if (!isEmpty(storagePriceType)) {
      setPriceType(storagePriceType)

      return
    }

    setPriceType(updatedDefaultPriceType)
  }, [isMarketplace])

  return (
    <PriceTypeContext.Provider
      value={{
        currentPriceType,
        isNettoPriceType,
        setPriceType,
      }}
    >
      {children}
    </PriceTypeContext.Provider>
  )
}

export default PriceTypeContext
