import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import type { PriceMolProps } from "@onestore/hel/dist/components/molecules/PriceMol"
import PriceMol from "@onestore/hel/dist/components/molecules/PriceMol"
import {
  getSecondaryPrices,
  getStaticLowestPrice,
  getStaticPrices,
  getStaticRegularPrice,
} from "@gatsby-plugin-generic-page/helpers/staticPrices"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import type { StaticPrice } from "~/fragments/staticPrice"
import isEmpty from "~/lib/isEmpty"

export type DetailedPriceProps = {
  variant: PriceMolProps["variant"]
  staticPrices: StaticPrice[]
  period: string
  additionalInfo?: string
}

const DetailedStaticPrice = ({
  variant,
  staticPrices,
  period,
  additionalInfo,
}: DetailedPriceProps): ReactElement<DetailedPriceProps> | null => {
  const { currentPriceType } = usePriceTypeContext()

  const prices = staticPrices
    ? getStaticPrices(staticPrices, currentPriceType)
    : {}

  if (isEmpty(prices) || isEmpty(prices[period])) {
    return null
  }

  const crossedPrices = staticPrices
    ? getSecondaryPrices(staticPrices, currentPriceType)
    : {}

  const regularPrices = staticPrices
    ? getStaticRegularPrice(staticPrices, currentPriceType)
    : {}

  const lowestPrices = staticPrices
    ? getStaticLowestPrice(staticPrices, currentPriceType)
    : {}

  const monthlyPrice = prices[period].monthlyPrice

  const priceMonthlyElement = !isEmpty(monthlyPrice) ? (
    <>
      {monthlyPrice.prefix}{" "}
      <TextAtm hasFitLineHeight isBold>
        {`${monthlyPrice.value} ${monthlyPrice.suffix}`}
      </TextAtm>
    </>
  ) : null

  const additionalInformation = !isEmpty(prices[period]?.renewPrice)
    ? `${_t("prices.renewalPrefix")} ${prices[period].renewPrice?.value} ${prices[period].renewPrice?.suffix}`
    : additionalInfo

  const hasPromotion =
    !isEmpty(crossedPrices) &&
    crossedPrices[period] &&
    !isEmpty(regularPrices) &&
    !isEmpty(regularPrices[period]) &&
    !isEmpty(lowestPrices) &&
    !isEmpty(lowestPrices[period])

  return (
    <PriceMol
      variant={variant}
      mainPrice={{
        currentPrice: prices[period].price.value,
        crossedPrice: hasPromotion ? crossedPrices[period] : undefined,
        periodText: prices[period].price.suffix,
      }}
      badgeText={priceMonthlyElement}
      additionalInformation={
        additionalInformation ? [additionalInformation] : []
      }
      regularPrice={
        hasPromotion
          ? {
              text: _t("prices.regularPriceWithType", {
                priceType: _t(`prices.${currentPriceType}`),
              }),
              price: regularPrices[period].price,
              percent: regularPrices[period].percent,
            }
          : undefined
      }
      lowestPrice={
        hasPromotion
          ? {
              text: _t("prices.lowestPriceWithType", {
                priceType: _t(`prices.${currentPriceType}`),
              }),
              price: lowestPrices[period].price,
              percent: lowestPrices[period].percent,
            }
          : undefined
      }
    />
  )
}

export default DetailedStaticPrice
