import type { PriceType } from "@core/types"
import type { BasketActionSource } from "@gatsby-plugin-basket/store/actions"
import log from "~/lib/log"
import type { EventCategory } from "./ga4"
import Storage from "./storage"
import SessionStorage from "./storage/SessionStorage"

export type TransactionProduct = {
  id: string
  name: string
  category?: string
  price: number
  quantity?: number
  variant?: string
  period_name?: string
  productPlanId: number | null
}

export type CardItem = {
  id?: number
  item_id: string
  item_name: string
  category?: string
  price: number
  quantity?: number
  product_plan_id?: number
  currency: string
  index: number | null
  affiliation?: string
  item_category?: string
  item_variant?: string
  cart_id?: string
  cart_index?: number
}

export type PageViewGA4DataLayer = {
  event: "page_view"
  page: {
    url: string
    old_url: string | null
    type: string | null
  }
  article: null
  user_status_adblock: boolean
  user_status_price_type: string
}

export type EcommerceOrderGA4DataLayer = {
  event: "purchase"
  event_type: "ecommerce"
  ecommerce: {
    transaction_id: string
    value: number
    currency: string
    payment_type: number
    system_id?: string
    items: CardItem[]
  }
}

export type EcommerceBasketShowGA4DataLayer = {
  event: "view_cart"
  event_type: "ecommerce"
  ecommerce: {
    value: number
    currency: string
    items: CardItem[]
  }
}

export type EcommerceBasketBeginCheckoutGA4DataLayer = {
  event: "begin_checkout"
  event_type: "ecommerce"
  ecommerce: {
    value: number
    currency: string
    items: CardItem[]
  }
}

export type EcommerceAddProductGA4DataLayer = {
  event: "add_to_cart"
  event_type: "ecommerce"
  cart_id: string | null
  ecommerce: {
    source: BasketActionSource | string | null
    items: CardItem[]
  }
}

export type EcommerceProductRemoveGA4DataLayer = {
  event: "remove_from_cart"
  event_type: "ecommerce"
  ecommerce: {
    items: CardItem[]
  }
}

export type EcommerceBasketChangePaymentGA4DataLayer = {
  event: "add_payment_info"
  event_type: "ecommerce"
  ecommerce: {
    currency: string
    value: number
    payment_type: number
    system_id: string
    items: CardItem[]
  }
}

export type EcommerceSelectItemGA4DataLayer = {
  event: "select_item"
  event_type: "ecommerce"
  ecommerce: {
    items: CardItem[]
  }
}

export type EcommerceSelectDomainGA4DataLayer = {
  event: "select_item"
  event_type: "ecommerce"
  ecommerce: {
    items: {
      item_id: number
      item_name?: string
      currency: string
      price?: number
      item_category: "domain"
      item_variant: string
      index: number | null
      quantity: string
      affiliation: string
    }[]
  }
}

export type EcommerceCheckoutDataLayer = {
  event: "EEremoveFromCart"
  pageType?: string
  ecommerce: {
    checkout: {
      actionField: string
      products?: TransactionProduct[]
    }
  }
}

export type CmsObjectEvent = {
  event: string
  event_type: string
  object_category: string
  object_name: string
}

export type AiSearchEvent = {
  event: string
  event_type: string
  search_term: string
  status: string
}

export type UserEvent = {
  event: string
  event_type: string
  user_id?: string | null
}

export type MenuViewEvent = {
  event: "menu_view"
  event_type: "ux_interaction"
}

export type FaqShowEvent = {
  event: "faq_show"
  event_type: "ux_interaction"
  faq_subject: string
}

export type ContentShowEvent = {
  event: "content_show"
  event_type: "ux_interaction"
  eventSource?: string
}

export type ToplayerShowEvent = {
  event: string
  event_type: string
  toplayer_name: string | null
}

export type SiteSearchResultStatus =
  | "available"
  | "unavailable"
  | "unavailable_with_premium"
  | "domain_transfer"
  | "domain_transfer_with_premium"
  | "premium"

export type SiteSearchResultGa4DataLayer = {
  event: string
  event_type: string
  search_term: string
  status?: SiteSearchResultStatus
}

export type FormEvent = {
  event: EventCategory
  event_type: string
  form_type?: string
  form_provider: string
  form_id?: string
}

export type ProductBoxClickEvent = {
  event: string
  event_type: string
  productbox_type: string
  productbox_option: string
  productbox_checked_item?: string | number
  productboxbox_time?: string
  productboxbox_package?: string
}

// Required legacy GA3 - ONESTORE-399
export type PageViedDataLayer = {
  event: "PageView"
  page: string
}

// Required legacy GA3 - ONESTORE-399
export type EcommerceOrderDataLayer = {
  event: "order"
  pageType: string
  orderId: string
  orderNumber: string
  orderValue: string
  userType: string
  orderPriceType: PriceType | null
  ecommerce: {
    currencyCode: string
    purchase: {
      actionField: {
        id: string
        affiliation: string
        revenue: string
        tax: number
        shipping: number
      }
      products: TransactionProduct[]
    }
  }
}

// Required legacy GA3 - ONESTORE-399
export type EcommerceAddProductDataLayer = {
  event: "addToCart"
  ecommerce: {
    currencyCode: string
    add: {
      products: TransactionProduct[]
    }
  }
}

export function dataLayerPush<
  T extends
    | EcommerceOrderGA4DataLayer
    | EcommerceAddProductGA4DataLayer
    | EcommerceBasketShowGA4DataLayer
    | EcommerceBasketBeginCheckoutGA4DataLayer
    | EcommerceSelectItemGA4DataLayer
    | EcommerceSelectDomainGA4DataLayer
    | PageViewGA4DataLayer
    | EcommerceProductRemoveGA4DataLayer
    | EcommerceBasketChangePaymentGA4DataLayer
    | EcommerceCheckoutDataLayer
    | CmsObjectEvent
    | UserEvent
    | MenuViewEvent
    | FaqShowEvent
    | ContentShowEvent
    | ToplayerShowEvent
    | SiteSearchResultGa4DataLayer
    | FormEvent
    | ProductBoxClickEvent
    | EcommerceOrderDataLayer
    | EcommerceAddProductDataLayer
    | PageViedDataLayer,
>(data: T): void {
  log(data, "datalayer")

  window.dataLayer.push(data)
}

export function pushLocationChange(
  locationPath: string,
  prevPath: string | null,
  ga3LocationPath: string
): void {
  dataLayerPush<PageViedDataLayer>({
    event: "PageView",
    page: ga3LocationPath,
  })

  dataLayerPush<PageViewGA4DataLayer>({
    event: "page_view",
    page: {
      url: locationPath,
      old_url: prevPath,
      type: null,
    },
    article: null,
    user_status_adblock: SessionStorage.get("_oab") !== "none",
    user_status_price_type: Storage.getLastPriceType(),
  })
}
