import { fetchDedupe } from "fetch-dedupe"
import type { BasketParamResponseItem, PriceValue } from "@onestore/api/basket"
import type { RemoteProduct } from "@onestore/api/product"
import type { PlanId, ProductAlias } from "@onestore/api/types"
import type { HTTP } from "@onestore/onestore-store-common"
import { HTTP_STATUS } from "@onestore/onestore-store-common/http"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type { ProductDefinitionResourceCategory } from "@gatsby-plugin-definitions/lib/normalizers"
import { apiUrl } from "~/lib/api"
import { build } from "~/lib/config"
import SessionStorage from "~/lib/storage/SessionStorage"
export const PRODUCT_DEFINITION_GET_REQUEST =
  "@definitions/PRODUCT_DEFINITION_GET_REQUEST"
export const PRODUCT_DEFINITION_GET_SUCCESS =
  "@definitions/PRODUCT_DEFINITION_GET_SUCCESS"
export const PRODUCT_DEFINITION_GET_FAILURE =
  "@definitions/PRODUCT_DEFINITION_GET_FAILURE"

export interface PeriodResourceRate {
  price: PriceValue
  lower_limit: number
  upper_limit: number
  included_value: number
}

export interface ProductDefinition {
  resource_categories: ProductDefinitionResourceCategory[]
  description: string
  id: PlanId
  name: string
  default_plan_period: RemoteProduct["default_plan_period"]
  resources: RemoteProduct["resources"]
  periods: CloudBluePeriod[]
  priority: number
  promoted: boolean
  advanced_configuration: boolean
  definition_type: RemoteProduct["definition_type"]
  product_type: RemoteProduct["product_type"]
  parameters: Record<string, BasketParamResponseItem>
  alias: ProductAlias
  min_quantity: number | null
  max_quantity: number | null
}

export interface ProductDefinitionGetRequestAction {
  alias: ProductAlias
  type: "@definitions/PRODUCT_DEFINITION_GET_REQUEST"
}

export interface ProductDefinitionGetSuccessAction {
  alias: ProductAlias
  product: ProductDefinition
  status: HTTP.Status
  type: "@definitions/PRODUCT_DEFINITION_GET_SUCCESS"
}

export interface ProductDefinitionGetFailureAction {
  type: "@definitions/PRODUCT_DEFINITION_GET_FAILURE"
}

export type ProductDefinitionActions =
  | ProductDefinitionGetRequestAction
  | ProductDefinitionGetSuccessAction
  | ProductDefinitionGetFailureAction

function fetchDefinitionWithCache(alias: ProductAlias): ProductDefinition {
  const buildKey = `def-${build || ""}-${alias}`
  const cachedDefinition = SessionStorage.get(buildKey)

  if (cachedDefinition) {
    try {
      return JSON.parse(cachedDefinition)
    } catch (e) {
      // ignore
    }
  }

  return fetchDedupe(`${apiUrl}/products/${alias}/definitions`, {
    method: "GET",
  })
    .then((res) => res.data)
    .then((data) => {
      SessionStorage.set(buildKey, JSON.stringify(data))

      return data
    })
}

export function requestProductDefinition(alias: ProductAlias) {
  return async (dispatch) => {
    dispatch({
      type: PRODUCT_DEFINITION_GET_REQUEST,
      status: HTTP_STATUS.CONTINUE,
      alias,
    } as ProductDefinitionGetRequestAction)

    try {
      const product = await fetchDefinitionWithCache(alias)

      dispatch({
        type: PRODUCT_DEFINITION_GET_SUCCESS,
        status: HTTP_STATUS.OK,
        alias,
        product,
      } as ProductDefinitionGetSuccessAction)

      return Promise.resolve(product)
    } catch (error) {
      dispatch({
        type: PRODUCT_DEFINITION_GET_FAILURE,
        status: HTTP_STATUS.BAD_REQUEST,
        alias,
      } as ProductDefinitionGetFailureAction)

      return Promise.reject(error)
    }
  }
}
