import type { ReactElement } from "react"
import React, { useEffect } from "react"
import type { CheckMainDomainResponse } from "lib/api/types"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import type { LabelVariant } from "@onestore/hel/dist/components/atoms/LabelAtm/LabelAtm.types"
import PlaceholderAtm from "@onestore/hel/dist/components/atoms/PlaceholderAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import DomainSearchResultBoxOrg from "@onestore/hel/dist/components/organisms/DomainSearchResultBoxOrg"
import type { AdditionalInfoProps } from "@onestore/hel/dist/components/organisms/DomainSearchResultBoxOrg/AdditionalInfo"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import SubmitInputWrapper from "@onestore/hel/dist/pages/SearchPage/common/SubmitInputWrapper"
import PremiumButton from "@gatsby-plugin-domain-search/components/Button/PremiumButton"
import { DomainName } from "@gatsby-plugin-domain-search/components/MainResultBox/DomainName"
import { Footer } from "@gatsby-plugin-domain-search/components/MainResultBox/Footer"
import { MainResultImage } from "@gatsby-plugin-domain-search/components/MainResultBox/MainResultImage"
import { MainResultLoader } from "@gatsby-plugin-domain-search/components/MainResultBox/MainResultLoader"
import PremiumAdditionalInfoBox from "@gatsby-plugin-domain-search/components/MainResultBox/PremiumAdditionalInfoBox"
import PremiumMainResultBox from "@gatsby-plugin-domain-search/components/MainResultBox/PremiumMainResultBox"
import { getSpecialSubItemElement } from "@gatsby-plugin-domain-search/components/MainResultBox/SpecialSubItem"
import ProductResultBox from "@gatsby-plugin-domain-search/components/ProductResultBox"
import { getDomainPromoLabel } from "@gatsby-plugin-domain-search/lib/promo"
import { DomainSearchEvent } from "@gatsby-plugin-domain-search/store/constants"
import {
  getDomainSearchAlternativeDomain,
  getDomainSearchDomain,
  getDomainSearchPhrase,
  getDomainSearchPremium,
  getDomainSearchSpecialDomain,
  getUnavailableExtensions,
  isCurrentCheckDomainAvailable,
  isPhraseIdenticalToFqdn,
} from "@gatsby-plugin-domain-search/store/selectors"
import type { CtaSectionsModal } from "@gatsby-plugin-generic-page/fragments/ctaSectionsModal"
import useSiteMetadataQuery from "~/hooks/useSiteMetadataQuery"
import { scrollToAnchor } from "~/lib/dom"
import { sendSiteSearchResultEvent } from "~/lib/ga4"
import { md } from "~/lib/i18n"
import isEmpty from "~/lib/isEmpty"
import {
  TextAtmBold,
  TextAtmBoldError,
  TextAtmBoldSuccess,
} from "~/lib/markdown"
import { useAppSelector } from "~/store/hooks"
import { COMPONENT_ID } from "~/types/component"
import DomainTogglePriceType from "../DomainTogglePriceType"
import AlternativeMainResultBox from "./AlternativeMainResultBox"
import { Aside } from "./Aside"
import DomainTransferResultBox from "./DomainTransferResultBox"
import UnavailableExtensionsResultBox from "./UnavailableExtensionsResultBox"

interface MainResultBoxProps {
  sortingTooltipContent?: string | null
  mainDomainResult?: CheckMainDomainResponse
  hasScrollToMessageBox?: boolean
  ctaSectionsModal?: CtaSectionsModal
}

export function MainResultBox({
  sortingTooltipContent,
  mainDomainResult,
  hasScrollToMessageBox,
  ctaSectionsModal,
}: MainResultBoxProps): ReactElement {
  const { brand } = useSiteMetadataQuery()
  const isIonos = brand === "ionos"

  const phrase = useAppSelector(getDomainSearchPhrase)
  let mainDomain = useAppSelector(getDomainSearchDomain)
  let premiumDomain = useAppSelector(getDomainSearchPremium)
  let alternativeDomain = useAppSelector(getDomainSearchAlternativeDomain)
  let special = useAppSelector(getDomainSearchSpecialDomain)
  let available = useAppSelector(isCurrentCheckDomainAvailable)
  let isPhraseIdentical = useAppSelector(isPhraseIdenticalToFqdn)
  let unavailableExtensions = useAppSelector(getUnavailableExtensions)

  if (mainDomainResult) {
    mainDomain = mainDomainResult.domain
    alternativeDomain = mainDomainResult.alternative
    special = mainDomainResult.special
    available = mainDomainResult.domain.status === "Available"
    isPhraseIdentical = mainDomainResult.phrase === mainDomainResult.domain.fqdn
    unavailableExtensions = mainDomainResult.unavailable_extensions
  }

  const domainStatus = mainDomain?.status

  useEffect(() => {
    if (domainStatus === "Available") {
      if (hasScrollToMessageBox) {
        scrollToAnchor(COMPONENT_ID.MESSAGE_BOX)
      } else {
        scrollToAnchor(COMPONENT_ID.DOMAIN_SEARCH_MAIN_RESULT)
      }

      sendSiteSearchResultEvent(phrase, "available")
    }
  }, [domainStatus])

  if (!mainDomain) {
    return (
      <>
        <FlexContainerOrg justifyContent="flex-end">
          <PushOrg
            topSpace={1}
            bottomSpace={{ small: 4, medium: 2, large: 2 }}
            hasNoReset
          >
            <PlaceholderAtm widthSpace={16} heightSpace={3} />
          </PushOrg>
        </FlexContainerOrg>

        <MainResultLoader />
      </>
    )
  }

  let premiumAdditionalInfoJsx: AdditionalInfoProps | undefined = undefined

  if (premiumDomain && premiumDomain.fqdn === mainDomain.fqdn) {
    premiumAdditionalInfoJsx = {
      main: <PremiumAdditionalInfoBox />,
      aside: !isEmpty(premiumDomain.external_url) ? (
        <PremiumButton
          url={premiumDomain.external_url}
          domainName={`${premiumDomain.name}.${premiumDomain.extension}`}
          showTextOnMobile
        />
      ) : undefined,
    }
  }

  const subItems = special ? [getSpecialSubItemElement(special)] : undefined

  const priceToggleItem = !isIonos ? <DomainTogglePriceType /> : null

  if (
    !isEmpty(premiumDomain) &&
    !isPhraseIdentical &&
    mainDomain.status === "Unavailable"
  ) {
    sendSiteSearchResultEvent(phrase, "premium")

    return (
      <PremiumMainResultBox
        premiumDomain={premiumDomain}
        priceToggle={priceToggleItem}
        ctaSectionsModal={ctaSectionsModal}
        sortingTooltipContent={sortingTooltipContent}
      />
    )
  }

  if (
    ["Unavailable", "Unknown"].includes(mainDomain.status) &&
    alternativeDomain &&
    alternativeDomain?.status === "Available" &&
    !isPhraseIdentical
  ) {
    sendSiteSearchResultEvent(phrase, "available")

    return (
      <AlternativeMainResultBox
        domain={alternativeDomain}
        subItems={subItems}
        sortingTooltipContent={sortingTooltipContent}
        priceToggle={priceToggleItem}
        ctaSectionsModal={ctaSectionsModal}
        additionalInfo={premiumAdditionalInfoJsx}
      />
    )
  }

  if (
    ["Unavailable", "Unknown"].includes(mainDomain.status) &&
    !isPhraseIdentical
  ) {
    sendSiteSearchResultEvent(
      phrase,
      isEmpty(premiumAdditionalInfoJsx)
        ? "unavailable"
        : "unavailable_with_premium"
    )

    return (
      <UnavailableExtensionsResultBox
        unavailableExtensions={unavailableExtensions}
        subItems={subItems}
        footer={
          <Footer
            ctaSectionsModal={ctaSectionsModal}
            sortingTooltipContent={sortingTooltipContent}
          />
        }
        priceToggle={priceToggleItem}
        additionalInfo={premiumAdditionalInfoJsx}
      />
    )
  }

  if (
    ["Unavailable", "Invalid", "Error", "Unknown"].includes(mainDomain.status)
  ) {
    sendSiteSearchResultEvent(
      phrase,
      isEmpty(premiumAdditionalInfoJsx)
        ? "domain_transfer"
        : "domain_transfer_with_premium"
    )

    return (
      <DomainTransferResultBox
        unavailableExtensions={unavailableExtensions}
        domain={mainDomain}
        subItems={subItems}
        footer={
          <Footer
            ctaSectionsModal={ctaSectionsModal}
            sortingTooltipContent={sortingTooltipContent}
          />
        }
        priceToggle={priceToggleItem}
        additionalInfo={premiumAdditionalInfoJsx}
      />
    )
  }

  const availableText = isPhraseIdentical
    ? md("domainSearch.mainDomainHeaderTextAvailable", undefined, {
        strong: TextAtmBoldSuccess,
      })
    : md("domainSearch.mainDomainHeaderTextRecommended", undefined, {
        strong: TextAtmBold,
      })

  const promoLabel = getDomainPromoLabel(mainDomain.extension)

  let labelVariant = "successLight"

  if (!isPhraseIdentical) {
    labelVariant = "backgroundLightTint"
  }

  if (!available) {
    labelVariant = "errorLight"
  }

  return (
    <div id={COMPONENT_ID.DOMAIN_SEARCH_MAIN_RESULT}>
      <DomainSearchResultBoxOrg
        header={
          <LabelAtm
            variant={labelVariant as LabelVariant}
            emphasis="high"
            borderRadius="large2"
            size="large"
          >
            <TextAtm
              typography={{
                small: "xsmall1",
                medium: "small1",
                large: "small1",
              }}
            >
              {available
                ? availableText
                : md(
                    "domainSearch.mainDomainHeaderTextNotAvailable",
                    undefined,
                    {
                      strong: TextAtmBoldError,
                    }
                  )}
            </TextAtm>
          </LabelAtm>
        }
        priceToggle={priceToggleItem}
        image={
          <MainResultImage
            isPhraseIdenticalToFqdn={isPhraseIdentical}
            available={available}
          />
        }
        main={<DomainName label={promoLabel} domain={mainDomain} />}
        aside={
          <Aside
            mainDomain={mainDomain}
            eventSource={DomainSearchEvent.RECOMMENDED_DOMAIN_TEXT}
          />
        }
        footer={
          <Footer
            ctaSectionsModal={ctaSectionsModal}
            sortingTooltipContent={sortingTooltipContent}
          />
        }
        subItems={subItems}
        additionalInfo={premiumAdditionalInfoJsx}
      />
    </div>
  )
}

interface MainResultBoxContainerProps {
  sortingTooltipContent?: string | null
  ctaSectionsModal?: CtaSectionsModal
  hasScrollToMessageBox?: boolean
}

export default function MainResultBoxContainer({
  sortingTooltipContent,
  hasScrollToMessageBox,
  ctaSectionsModal,
}: MainResultBoxContainerProps) {
  return (
    <SubmitInputWrapper>
      <MainResultBox
        sortingTooltipContent={sortingTooltipContent}
        hasScrollToMessageBox={hasScrollToMessageBox}
        ctaSectionsModal={ctaSectionsModal}
      />
      <ProductResultBox />
    </SubmitInputWrapper>
  )
}
