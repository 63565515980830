import { get, replace, trim } from "lodash"
import type { MarkOptional } from "ts-essentials"
import type { BonusProduct } from "@gatsby-plugin-bonus/types"
import type {
  CloudBluePeriod,
  CloudBluePeriodUnit,
  ParentPromotionPeriod,
  ResourceData,
} from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import { parsePeriod, PeriodTypeEnum } from "../../lib/api"
import { safeFind } from "../../src/lib/collection"
import type { PeriodName, Price as PriceValue } from "../api/types"
import _t from "./i18n"
import {
  applyTaxRate,
  calculateDiscountPercent,
  calculateLowestPriceDiscountPercent,
  CURRENCY_CODE,
  CURRENCY_SEPARATOR,
  formatPriceObject,
  formatted,
  getCurrencyCode,
  getCurrencySeparator,
  getCurrencySign,
  getDiscountPercent,
  getLowestPriceDiscountPercent,
  getPeriodPricePerMonth,
  getPriceObjectWithTaxRate,
  roundUpPrice,
} from "./pricing"
import type { FormattedPricesObject, MonthlyPrice } from "./types"
import { PriceFormat, PriceType } from "./types"

export function canConvertToMonthlyPrice(
  periodType: PeriodTypeEnum | null,
  periodValue: number | null
) {
  return (
    (periodType === PeriodTypeEnum.YEAR ||
      periodType === PeriodTypeEnum.MONTH) &&
    periodValue !== null
  )
}

export const getFormattedPrices = (
  periodInfo: PeriodInfo
): FormattedPricesObject => {
  const price_register = periodInfo.getRegisterPriceWithResources()
  const price_no_promo = periodInfo.getNoPromoPriceWithResources()
  const lowest_purchase_price_value =
    periodInfo.getLowestPurchasePriceWithResources()

  const period = periodInfo.period

  return {
    register: {
      netto: formatted(
        getPriceObjectWithTaxRate(price_register, period.tax_rate),
        PriceType.NETTO
      ),
      gross: formatted(
        getPriceObjectWithTaxRate(price_register, period.tax_rate),
        PriceType.GROSS
      ),
    },
    renew: {
      netto: formatted(
        getPriceObjectWithTaxRate(
          periodInfo.getRenewalPriceWithResources(),
          period.tax_rate
        ),
        PriceType.NETTO
      ),
      gross: formatted(
        getPriceObjectWithTaxRate(
          periodInfo.getRenewalPriceWithResources(),
          period.tax_rate
        ),
        PriceType.GROSS
      ),
    },
    no_promo: {
      netto: formatted(
        getPriceObjectWithTaxRate(price_no_promo, period.tax_rate),
        PriceType.NETTO
      ),
      gross: formatted(
        getPriceObjectWithTaxRate(price_no_promo, period.tax_rate),
        PriceType.GROSS
      ),
    },
    discount: {
      netto: formatted(
        getPriceObjectWithTaxRate(
          price_no_promo - price_register,
          period.tax_rate
        ),
        PriceType.NETTO
      ),
      gross: formatted(
        getPriceObjectWithTaxRate(
          price_no_promo - price_register,
          period.tax_rate
        ),
        PriceType.GROSS
      ),
      percent: getDiscountPercent(
        getPriceObjectWithTaxRate(price_no_promo, period.tax_rate),
        getPriceObjectWithTaxRate(price_register, period.tax_rate),
        PriceType.GROSS
      ),
    },
    renewal_discount: {
      netto: formatted(
        getPriceObjectWithTaxRate(
          periodInfo.getRenewalPriceWithResources() - price_register,
          period.tax_rate
        ),
        PriceType.NETTO
      ),
      gross: formatted(
        getPriceObjectWithTaxRate(
          periodInfo.getRenewalPriceWithResources() - price_register,
          period.tax_rate
        ),
        PriceType.GROSS
      ),
      percent: getDiscountPercent(
        getPriceObjectWithTaxRate(
          periodInfo.getRenewalPriceWithResources(),
          period.tax_rate
        ),
        getPriceObjectWithTaxRate(price_register, period.tax_rate),
        PriceType.GROSS
      ),
    },
    lowest_purchase_price: lowest_purchase_price_value
      ? {
          netto: formatted(
            getPriceObjectWithTaxRate(
              lowest_purchase_price_value,
              period.tax_rate
            ),
            PriceType.NETTO
          ),
          gross: formatted(
            getPriceObjectWithTaxRate(
              lowest_purchase_price_value,
              period.tax_rate
            ),
            PriceType.GROSS
          ),
          percent: getLowestPriceDiscountPercent(
            getPriceObjectWithTaxRate(price_register, period.tax_rate),
            getPriceObjectWithTaxRate(
              lowest_purchase_price_value,
              period.tax_rate
            ),
            PriceType.GROSS
          ),
        }
      : null,
  }
}

export interface PeriodInfoPeriod
  extends Pick<
    CloudBluePeriod,
    | "first_commitment_price"
    | "commitment_count"
    | "commitment_price"
    | "commitment_period"
    | "renewal_period"
    | "has_multiple_commitments"
    | "one_time_fee_item"
    | "has_promo_limit"
    | "period_name"
    | "promoted"
  > {
  price_no_promo: number
  price_renew: number
  price_register: number
  purchase_price: number
  lowest_purchase_price?: number | null
  tax_rate: number // TODO usunąć w następnej iteracji zmian CBCUPDATE-116
  resource_data: ResourceData[]
  trial?: boolean
  parent_promotions: ParentPromotionPeriod[]
  plan_id: number | null
}

export type PeriodInfoPeriodWithId = PeriodInfoPeriod & { id: number }

export type UsePrices = {
  priceValue: string
  oldPriceValue: string
  promotionPercent: string
  lowestPrice: string
  lowestPricePercent: string
  renewPrice: string
}

function normalizeLowestPrice(price: null | number | undefined): number {
  return price ?? 0
}

export function combinedPeriods(
  periodValue: PeriodInfoPeriod,
  bundledPeriodValue: PeriodInfoPeriod
): PeriodInfoPeriod {
  const parentPromoBundledPriceRegister =
    bundledPeriodValue.parent_promotions.find(
      (item) => item.parent_plan_id === periodValue.plan_id
    ) ?? null

  const period_name = periodValue.period_name
  const price_no_promo =
    periodValue.price_no_promo +
    (parentPromoBundledPriceRegister
      ? parentPromoBundledPriceRegister.price_register
      : bundledPeriodValue.price_no_promo)
  const price_renew = periodValue.price_renew + bundledPeriodValue.price_renew
  const price_register =
    periodValue.price_register +
    (parentPromoBundledPriceRegister
      ? parentPromoBundledPriceRegister.price_register
      : bundledPeriodValue.price_register)
  const purchase_price =
    periodValue.purchase_price + bundledPeriodValue.purchase_price
  const tax_rate = periodValue.tax_rate

  const lowest_purchase_price =
    normalizeLowestPrice(
      periodValue.promoted
        ? periodValue.lowest_purchase_price
        : periodValue.price_register
    ) +
    (parentPromoBundledPriceRegister
      ? normalizeLowestPrice(
          parentPromoBundledPriceRegister?.lowest_purchase_price
        )
      : normalizeLowestPrice(bundledPeriodValue.lowest_purchase_price))

  return {
    first_commitment_price: undefined,
    commitment_count: undefined,
    commitment_price: undefined,
    commitment_period: undefined,
    renewal_period: undefined,
    has_multiple_commitments: false,
    period_name,
    price_no_promo,
    price_renew,
    price_register,
    purchase_price,
    tax_rate,
    promoted: periodValue.promoted || bundledPeriodValue.promoted,
    plan_id: null,
    parent_promotions: [],
    has_promo_limit:
      periodValue.has_promo_limit || bundledPeriodValue.has_promo_limit,
    lowest_purchase_price,
    resource_data: [
      ...(periodValue.resource_data ?? []),
      ...(bundledPeriodValue.resource_data ?? []),
    ],
  }
}

export type PeriodInfoResourceSetup = { resource_id: number; quantity: number }

export class PeriodInfo {
  readonly period: PeriodInfoPeriod
  readonly resourceSetup: PeriodInfoResourceSetup[]

  constructor(
    readonly periodValue: PeriodInfoPeriod,
    bundledPeriodValue: PeriodInfoPeriod | null = null,
    resourceSetup: PeriodInfoResourceSetup[] | null = null
  ) {
    this.period =
      bundledPeriodValue === null
        ? periodValue
        : combinedPeriods(periodValue, bundledPeriodValue)

    this.resourceSetup =
      resourceSetup === null ? this.getDefaultResourceSettings() : resourceSetup
  }

  public getPeriodObject(): PeriodInfoPeriod {
    return this.period
  }

  private getPriceFormatData() {
    const prices = getFormattedPrices(this)

    return {
      ...prices,
      period: {
        name: this.getPeriodName(),
      },
      separator: getCurrencySeparator(),
      type: {
        // w przypadku gdy stawka wynosi 0 wyswietlamy tekst dla netto
        gross:
          this.period.tax_rate === 0 ? _t("prices.netto") : _t("prices.gross"),
        netto: _t("prices.netto"),
      },
    }
  }

  private getResourceData(): ResourceData[] {
    return "resource_data" in this.period ? this.period.resource_data || [] : []
  }

  public getBonusPeriodName(): string {
    if (this.isOneTimeFee()) {
      return _t("periodName.oneTimeFee")
    }

    return _t(
      this.period.trial
        ? `periodName.basketTrial.${this.getPeriodType()}`
        : `periodName.bonus.${this.getPeriodType()}`,
      { smart_count: this.getPeriodLength() }
    )
  }

  private getDefaultResourceSettings(): PeriodInfoResourceSetup[] {
    const resourceData = this.getResourceData()

    return resourceData
      .filter(
        ({ lower_limit, included_value, is_category_default }) =>
          is_category_default ||
          lower_limit - (included_value >= 0 ? included_value : 0) > 0
      )
      .map(
        (item: ResourceData): PeriodInfoResourceSetup => ({
          resource_id: item.resource_id,
          quantity:
            item.lower_limit -
            (item.included_value >= 0 ? item.included_value : 0),
        })
      )
  }

  public getResourcesIncludedInDefaultConfiguration(): ResourceData[] {
    const resourceData = this.getResourceData()

    const resourceList = this.resourceSetup
      .filter(({ quantity }) => quantity > 0)
      .map(({ resource_id }) => resource_id)

    return resourceData.filter(({ resource_id }) =>
      resourceList.includes(resource_id)
    )
  }

  private getResourcesPrice(): number {
    return this.getResourcesIncludedInDefaultConfiguration()
      .map((item) => item.purchase_price)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  }

  public getResourcePriceValue(resourceId: number): number | null {
    const resourceData = this.getResourceData()

    const searchedResource = resourceData.find(
      (resource) => resource.resource_id === resourceId
    )

    return searchedResource
      ? searchedResource.recurring_fee * this.getCommitmentCount() +
          searchedResource.setup_fee
      : null
  }

  public getResourcePriceWithCurrency(
    resourceId: number | undefined
  ): string | null {
    if (resourceId === undefined) {
      return null
    }

    const priceValue = this.getResourcePriceValue(resourceId)

    if (priceValue === null) {
      return null
    }

    const resourcePrice = getPriceObjectWithTaxRate(
      priceValue,
      this.period.tax_rate
    )

    return formatPriceObject(resourcePrice, PriceType.GROSS)
  }

  private getCommitmentCount(): number {
    return this.period.commitment_count ?? 1
  }

  private getResourcesPriceNoPromo(): number {
    return this.getResourcesIncludedInDefaultConfiguration()
      .map(
        (resource) =>
          (resource.setup_fee +
            resource.recurring_fee *
              this.getResourceQuantity(resource.resource_id)) *
          this.getCommitmentCount()
      )
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  }

  private getResourcesLowestPrice(): number {
    return this.getResourcesIncludedInDefaultConfiguration()
      .map((item) =>
        item.lowest_purchase_price
          ? item.lowest_purchase_price *
            this.getResourceQuantity(item.resource_id)
          : 0
      )
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  }

  public getRegisterPriceWithResources(): number {
    return this.period.price_register + this.getResourcesPrice()
  }

  public getNoPromoPriceWithResources(): number {
    return this.period.price_no_promo + this.getResourcesPriceNoPromo()
  }

  public getLowestPurchasePriceWithResources(): number {
    const lowestPurchasePrice =
      (this.period.lowest_purchase_price ?? 0) + this.getResourcesLowestPrice()

    return lowestPurchasePrice === 0
      ? this.getNoPromoPriceWithResources()
      : lowestPurchasePrice
  }

  public getPeriodNameRaw(): string {
    return this.period.period_name
  }

  public getPeriodLength(): number {
    return parsePeriod(this.period.period_name).periodValue
  }

  public getPeriodType(): number | null {
    if (this.period.period_name.length < 2) {
      return null
    }

    return parsePeriod(this.period.period_name).periodType
  }

  public hasPromotion(): boolean {
    return this.period.promoted ?? false
  }

  public hasLimit(): boolean {
    return this.period.has_promo_limit
  }

  public isTrial(): boolean {
    return this.period.trial ?? false
  }

  public hasPeriod(): boolean {
    return this.getPeriodLength() <= 0
  }

  public isOneTimeFee(): boolean {
    if (this.getPeriodLength() === 0) {
      return true
    }

    return this.period.one_time_fee_item ?? false
  }

  public getPriceType(): string {
    return _t("prices.netto")
  }

  public getFormatted(format: PriceFormat): string {
    return replace(format, /\{[a-z._]+\}/gm, (token: string): string => {
      return get(this.getPriceFormatData(), trim(token, "{}"), "") as string
    })
  }

  public getActualPrice(
    isDomain: boolean,
    quantityDivider: number
  ): PriceValue {
    const regularPrice = this.getRegularPriceValue(isDomain)
    const promoPrice = this.getPromoPriceValue(quantityDivider)

    const hasPromotion =
      promoPrice !== null && regularPrice.netto !== promoPrice.netto

    return hasPromotion && promoPrice ? promoPrice : regularPrice
  }

  public getFormattedWhenPromoted(format: PriceFormat): string | undefined {
    if (!this.hasPromotion()) {
      return undefined
    }

    return this.getFormatted(format)
  }

  /**
   * Metoda zwraca koszykową cenę rejestracji usługi.
   */
  public getRegisterPriceValue(): PriceValue {
    return {
      taxRate: this.period.tax_rate,
      currency: getCurrencySign(),
      gross: applyTaxRate(
        this.getRegisterPriceWithResources(),
        this.period.tax_rate
      ),
      netto: this.getRegisterPriceWithResources(),
    }
  }

  public getMonthlyPriceValue(): MonthlyPrice | null {
    const { periodType, periodValue } = parsePeriod(this.period.period_name)

    if (canConvertToMonthlyPrice(periodType, periodValue)) {
      const divider =
        (periodType === PeriodTypeEnum.MONTH ? 1 : 12) * (periodValue as number)

      const priceValue = {
        currency: getCurrencyCode(),
        gross:
          applyTaxRate(
            this.getRegisterPriceWithResources(),
            this.period.tax_rate
          ) / divider,
        netto: this.getRegisterPriceWithResources() / divider,
      }

      // price less than 50 is less than 0.01 after format and round up, so return null because it's illegal to round up
      if (priceValue.gross < 50 || priceValue.netto < 50) {
        return null
      }

      const grossRounded = roundUpPrice(priceValue.gross)
      const nettoRunded = roundUpPrice(priceValue.netto)

      return {
        taxRate: this.period.tax_rate,
        currency: getCurrencyCode(),
        gross: `${grossRounded.replace(".", CURRENCY_SEPARATOR)} ${CURRENCY_CODE}`,
        netto: `${nettoRunded.replace(".", CURRENCY_SEPARATOR)} ${CURRENCY_CODE}`,
      }
    }

    return null
  }

  public getPromoPricePorPlanPrice(plan: number): PriceValue | null {
    const parent =
      this.period.parent_promotions.find(
        (item) => item.parent_plan_id === plan
      ) ?? null

    if (parent === null) {
      return null
    }

    return getPriceObjectWithTaxRate(parent.price_register, parent.tax_rate, 1)
  }

  public getRegularPriceValue(isDomain: boolean): PriceValue {
    const noPromo = this.getNoPromoPriceWithResources()
    const domainPrice =
      this.period.price_register === noPromo
        ? this.getRenewalPriceWithResources()
        : noPromo

    const basicRenewalPrice =
      this.getRenewalPriceWithResources() > 0 && noPromo === 0
        ? this.getRenewalPriceWithResources()
        : noPromo

    if (this.isOneTimeFee()) {
      return getPriceObjectWithTaxRate(noPromo, this.period.tax_rate)
    }

    if (isDomain) {
      return getPriceObjectWithTaxRate(domainPrice, this.period.tax_rate)
    }

    if (this.hasPromotion()) {
      return getPriceObjectWithTaxRate(basicRenewalPrice, this.period.tax_rate)
    }

    return this.getRegisterPriceValue()
  }

  public getRenewalPriceValue(quantityDivider: number = 1): PriceValue | null {
    return this.isOneTimeFee()
      ? null
      : getPriceObjectWithTaxRate(
          this.getRenewalPriceWithResources(),
          this.period.tax_rate,
          quantityDivider
        )
  }

  /**
   * Metoda zwraca cenę "promocyjną" tj. cenę rejestracji jesli produkt ma promocję.
   *
   * @param quantityDivider
   */
  public getPromoPriceValue(quantityDivider: number): PriceValue | null {
    return this.hasPromotion() ? this.getRegisterPriceValue() : null
  }

  public getLowestPriceValue(): PriceValue {
    return getPriceObjectWithTaxRate(
      this.getLowestPurchasePriceWithResources(),
      this.period.tax_rate
    )
  }

  public getLowestPricePerMonthValue() {
    const lowestPricePerMonth =
      this.getPeriodType() === null || !this.period.lowest_purchase_price
        ? undefined
        : getPeriodPricePerMonth(
            this.period.lowest_purchase_price,
            this.getPeriodNameRaw()
          )

    if (lowestPricePerMonth === undefined) {
      return undefined
    }

    return getPriceObjectWithTaxRate(
      parseInt(lowestPricePerMonth.toFixed(0), 10),
      this.period.tax_rate
    )
  }

  public getDiscount(
    isDomain: boolean,
    quantityDivider: number = 1
  ): number | null {
    const promoPrice = this.getPromoPriceValue(quantityDivider)

    return promoPrice === null
      ? null
      : calculateDiscountPercent(
          this.getRegularPriceValue(isDomain),
          promoPrice,
          PriceType.GROSS
        )
  }

  public getDiscountForProduct(quantityDivider: number = 1): number | null {
    const promoPrice = this.getPromoPriceValue(quantityDivider)

    return promoPrice === null
      ? null
      : calculateDiscountPercent(
          getPriceObjectWithTaxRate(
            this.getNoPromoPriceWithResources(),
            this.period.tax_rate
          ),
          promoPrice,
          PriceType.GROSS
        )
  }

  public getLowestPriceDiscount(quantityDivider: number = 1): number | null {
    const lowestPrice = this.getLowestPriceValue()
    const promoPrice = this.getPromoPriceValue(quantityDivider)

    return lowestPrice === null || promoPrice === null
      ? null
      : calculateLowestPriceDiscountPercent(
          promoPrice,
          lowestPrice,
          PriceType.GROSS
        )
  }

  public getNoPromoPricePerMonth() {
    let noPromoPricePerMonth =
      this.getPeriodType() === null
        ? undefined
        : getPeriodPricePerMonth(
            this.period.price_no_promo,
            this.getPeriodNameRaw()
          )

    return noPromoPricePerMonth
      ? getPriceObjectWithTaxRate(
          parseInt(noPromoPricePerMonth.toFixed(0), 10),
          this.period.tax_rate
        )
      : undefined
  }

  public getPricePerMonth() {
    const pricePerMonth =
      this.getPeriodType() === null
        ? undefined
        : getPeriodPricePerMonth(
            this.getRegisterPriceWithResources(),
            this.getPeriodNameRaw()
          )

    return pricePerMonth
      ? getPriceObjectWithTaxRate(
          parseInt(pricePerMonth.toFixed(0), 10),
          this.period.tax_rate
        )
      : undefined
  }

  public getRenewalPriceWithResources(): number {
    const renewalResourcePrice =
      this.getResourcesIncludedInDefaultConfiguration()
        .map(
          (resource) =>
            resource.recurring_fee *
            this.getResourceQuantity(resource.resource_id)
        )
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

    return this.period.price_renew + renewalResourcePrice
  }

  public hasRenewalPrice(): boolean {
    return this.getRenewalPriceWithResources() > 0
  }

  public hasMultipleCommitments(): boolean {
    return this.period.has_multiple_commitments ?? false
  }

  public usePrices(
    priceType?: PriceType,
    hasHideCurrency?: boolean
  ): UsePrices {
    const priceValue =
      priceType === PriceType.NETTO
        ? this.getFormatted(PriceFormat.REGISTER_NETTO_NO_PERIOD)
        : this.getFormatted(PriceFormat.REGISTER_GROSS_NO_PERIOD)

    const priceValueWithoutCurrency =
      priceType === PriceType.NETTO
        ? this.getFormatted(PriceFormat.REGISTER_NETTO_NO_CURRENCY_NO_PERIOD)
        : this.getFormatted(PriceFormat.REGISTER_GROSS_NO_CURRENCY_NO_PERIOD)

    const oldPriceValue =
      priceType === PriceType.NETTO
        ? this.getFormatted(PriceFormat.NO_PROMO_NETTO_NO_PERIOD)
        : this.getFormatted(PriceFormat.NO_PROMO_GROSS_NO_PERIOD)

    const promotionPercent = this.getFormatted(PriceFormat.DISCOUNT_PERCENT)

    const lowestPrice =
      priceType === PriceType.NETTO
        ? this.getFormatted(PriceFormat.LOWEST_PURCHASE_PRICE_NETTO_NO_PERIOD)
        : this.getFormatted(PriceFormat.LOWEST_PURCHASE_PRICE_GROSS_NO_PERIOD)

    const lowestPriceWithoutCurrency =
      priceType === PriceType.NETTO
        ? this.getFormatted(
            PriceFormat.LOWEST_PURCHASE_PRICE_NETTO_NO_CURRENCY_NO_PERIOD
          )
        : this.getFormatted(
            PriceFormat.LOWEST_PURCHASE_PRICE_GROSS_NO_CURRENCY_NO_PERIOD
          )

    const renewPrice = this.hasRenewalPrice()
      ? priceType === PriceType.NETTO
        ? this.getFormatted(PriceFormat.RENEW_NETTO_NO_PERIOD)
        : this.getFormatted(PriceFormat.RENEW_GROSS_NO_PERIOD)
      : ""

    const lowestPricePercent = this.getFormatted(
      PriceFormat.LOWEST_PURCHASE_PRICE_PERCENT
    )

    return {
      priceValue: hasHideCurrency ? priceValueWithoutCurrency : priceValue,
      oldPriceValue,
      promotionPercent,
      lowestPrice: hasHideCurrency ? lowestPriceWithoutCurrency : lowestPrice,
      lowestPricePercent,
      renewPrice,
    }
  }

  getTaxRate(): number {
    return this.period.tax_rate
  }

  public getPeriodBasketLabel(isTransfer: boolean): string {
    return this.isOneTimeFee() && !isTransfer
      ? _t("periodName.oneTimeFee")
      : _t(`periodName.basket.${this.getPeriodType()}`, {
          smart_count: this.getPeriodLength(),
        })
  }

  public getMultipleCommitmentsPeriodText(): string {
    return [
      this.getCommitmentLabel(),
      _t(`periodName.commitmentRenewalPeriod.${this.getRenewalPeriodUnit()}`, {
        smart_count: this.getRenewalPeriodDuration(),
      }),
    ].join(", ")
  }

  public periodText(
    isTransfer: boolean = false,
    isShort: boolean = false
  ): string {
    if (this.hasMultipleCommitments() && !this.isOneTimeFee()) {
      return this.getMultipleCommitmentsPeriodText()
    }

    const periodTextName = isShort
      ? "periodName.priceMol."
      : "periodName.searchDomainPrice."

    return this.isOneTimeFee() && !isTransfer
      ? _t("periodName.oneTimeFee")
      : _t(periodTextName + this.getPeriodType(), {
          smart_count: this.getPeriodLength(),
        })
  }

  public getPeriodName(): string {
    if (this.isOneTimeFee()) {
      return _t("periodName.oneTimeFee")
    }

    if (this.period.period_name === "") {
      return ""
    }

    const { periodType, periodValue } = parsePeriod(this.period.period_name)

    return _t(`periodName.short.${periodType}`, {
      smart_count: periodValue,
    })
  }

  getCommitmentPeriodUnit(): CloudBluePeriodUnit | null {
    if (this.period.commitment_period) {
      return this.period.commitment_period.unit
    }

    return null
  }

  getRenewalPeriodDuration(): number {
    if (this.period.renewal_period) {
      return this.period.renewal_period.duration ?? 0
    }

    return 0
  }

  getRenewalPeriodUnit(): CloudBluePeriodUnit | null {
    if (this.period.renewal_period) {
      return this.period.renewal_period.unit
    }

    return null
  }

  public getCommitmentLabel(): string | null {
    if (
      this.hasMultipleCommitments() &&
      this.getCommitmentPeriodUnit() !== null
    ) {
      return _t(`prices.duration.${this.getCommitmentPeriodUnit()}`)
    }

    return null
  }

  private getResourceQuantity(resource_id: number) {
    for (const product of this.resourceSetup) {
      if (product.resource_id === resource_id) {
        return product.quantity
      }
    }

    return 0
  }
}

export function getFormattedPeriodInfo(
  periods: MarkOptional<CloudBluePeriod, "default">[],
  periodName: PeriodName | undefined,
  format: PriceFormat
): string {
  try {
    const periodData = safeFind(periods, (item) =>
      periodName ? item.period_name === periodName : item.default ?? false
    )
    const periodInfo = new PeriodInfo(periodData)

    return periodInfo.getFormatted(format)
  } catch {
    return ""
  }
}

export function getSelectedPeriodInfo(
  periods: MarkOptional<CloudBluePeriod, "default">[],
  periodName: PeriodName | undefined
): PeriodInfo | undefined {
  const periodFinder =
    periodName === undefined
      ? defaultPeriodFinder
      : (item) => item.period_name === periodName
  try {
    const periodData = safeFind(periods, periodFinder)

    return new PeriodInfo(periodData)
  } catch {
    return undefined
  }
}

export function defaultPeriodFinder(period: CloudBluePeriod): boolean {
  return period.default === true
}

export function getResourceSetupState(
  product: BonusProduct
): PeriodInfoResourceSetup[] {
  const resourceState: PeriodInfoResourceSetup[] = []
  Object.values(product.resources).forEach((resource) => {
    resourceState.push({
      resource_id: resource.id,
      quantity: resource.basketQuantity,
    })
  })

  return resourceState
}
