import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import PackageBoxSlimMol from "@onestore/hel/dist/components/molecules/PackageBoxSlimMol"
import { PriceVariantType } from "@onestore/hel/dist/components/molecules/PriceMol/priceMol.types"
import type { CtaElement } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import getBackground from "@gatsby-plugin-generic-page/helpers/getBackground"
import { getCtaButtonFlatData } from "@gatsby-plugin-generic-page/helpers/getCtaElementFlatData"
import { getStaticPrices } from "@gatsby-plugin-generic-page/helpers/staticPrices"
import ProductBoxPriceOldVersion from "~/components/ProductBoxPriceOldVersion"
import type { StaticPrice } from "~/fragments/staticPrice"
import usePriceConfigurationQuery from "~/hooks/usePriceConfigurationQuery"
import { sendGaEvent } from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"
import DetailedStaticPrice from "../DetailedStaticPrice"
import ProductBoxDiscountLabel from "./ProductBoxDiscountLabel"
import ProductBoxSlimFooter from "./ProductBoxFooter"

export type ProductBoxWithCtaProps = {
  product
  cta: CtaElement[]
  staticPrices?: StaticPrice[]
  isTrialBox?: boolean
  isDisabled?: boolean
}

export default function ProductBoxWithCta({
  product,
  cta,
  staticPrices,
  isTrialBox,
  isDisabled,
}: ProductBoxWithCtaProps): ReactElement<ProductBoxWithCtaProps> {
  const { showDetailedPrices } = usePriceConfigurationQuery()

  const prices = staticPrices
    ? getStaticPrices(staticPrices, product.priceType)
    : undefined

  if (isEmpty(cta)) {
    return <></>
  }

  const { text, title, variant, gaEvent, href, openInNewTab, pages } =
    getCtaButtonFlatData(cta[0])

  const period =
    prices && Object.keys(prices).length === 1
      ? Object.keys(prices)[0]
      : product.period || (prices && Object.keys(prices)[0])

  const { customOnColorText, imageBackground } = getBackground(
    product.backgroundImage
  )

  const renewalPayment =
    !isEmpty(prices) && prices[period].renewPrice
      ? _t("packageBoxSlim.renewalPrice", {
          price: prices[period].renewPrice?.value,
          suffix: prices[period].renewPrice?.suffix,
        })
      : product.additionalText || undefined

  let price: ReactElement | null = !isEmpty(prices) ? (
    <ProductBoxPriceOldVersion {...prices[period].price} />
  ) : null

  if (showDetailedPrices) {
    price = !isEmpty(staticPrices) ? (
      <DetailedStaticPrice
        variant={{
          type: PriceVariantType.LARGE,
          align: "default",
        }}
        staticPrices={staticPrices}
        period={period}
        additionalInfo={product.additionalText}
      />
    ) : null
  }

  return (
    <PackageBoxSlimMol
      {...product}
      button={
        !isDisabled
          ? {
              text: product.buttonText || text,
              title,
              href,
              to: pages?.length && pages[0].flatData.url,
              color: product.buttonColor,
              variant,
              openInNewTab,
              onClick: () => sendGaEvent(gaEvent),
            }
          : { text: product.buttonText || text, isDisabled: true }
      }
      price={price}
      renewalPayment={showDetailedPrices ? undefined : renewalPayment}
      label={
        !isEmpty(product.discountLabel) ? (
          <ProductBoxDiscountLabel
            label={product.discountLabel}
            variant={product.discountLabelVariant}
          />
        ) : null
      }
      parameters={product.content.parameters}
      footer={
        product.content?.footer ? (
          <ProductBoxSlimFooter {...product.content.footer} />
        ) : undefined
      }
      customTextColor={
        isTrialBox && customOnColorText ? customOnColorText : undefined
      }
      backgroundImage={
        isTrialBox && imageBackground ? imageBackground : undefined
      }
      isTrialBox={isTrialBox}
    />
  )
}
