import { useStaticQuery, graphql } from "gatsby"

interface Query {
  GraphCmsSquidex: {
    findPriceConfigurationSingleton: {
      flatData: {
        showPriceTypeSwitcher: boolean | null
        showDetailedPrices: boolean | null
      }
    }
  }
}

export default function usePriceConfigurationQuery() {
  const { GraphCmsSquidex } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        findPriceConfigurationSingleton {
          flatData {
            showPriceTypeSwitcher
            showDetailedPrices
          }
        }
      }
    }
  `)

  if (GraphCmsSquidex.findPriceConfigurationSingleton) {
    const { flatData } = GraphCmsSquidex.findPriceConfigurationSingleton

    return {
      showPriceTypeSwitcher: flatData.showPriceTypeSwitcher ?? false,
      showDetailedPrices: flatData.showDetailedPrices ?? false,
    }
  }

  return {
    showPriceTypeSwitcher: false,
    showDetailedPrices: false,
  }
}
