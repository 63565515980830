import type { ReactElement, ReactNode } from "react"
import React from "react"
import _t from "@core/i18n"
import type { PeriodInfo } from "@core/period-info"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import type { PriceMolProps } from "@onestore/hel/dist/components/molecules/PriceMol"
import PriceMol from "@onestore/hel/dist/components/molecules/PriceMol"
import TooltipMol from "@onestore/hel/dist/components/molecules/TooltipMol"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import { zIndex } from "@onestore/hel/dist/settings"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import type { PriceInfo } from "~/fragments/priceInfo"
import isEmpty from "~/lib/isEmpty"

export type DetailedPriceProps = {
  variant: PriceMolProps["variant"]
  periodInfo: PeriodInfo
  customRenewPriceTexts?: string[]
  priceInfo?: PriceInfo
}

const DetailedPrice = ({
  variant,
  periodInfo,
  customRenewPriceTexts,
  priceInfo,
}: DetailedPriceProps): ReactElement<DetailedPriceProps> => {
  const { currentPriceType, isNettoPriceType } = usePriceTypeContext()
  const hasPromotion = periodInfo.hasPromotion()

  const priceMonthly = isNettoPriceType
    ? periodInfo.getMonthlyPriceValue()?.netto
    : periodInfo.getMonthlyPriceValue()?.gross

  const {
    priceValue,
    oldPriceValue,
    promotionPercent,
    lowestPrice,
    lowestPricePercent,
    renewPrice,
  } = periodInfo.usePrices(currentPriceType)

  const priceSuffix = `${_t(`prices.${currentPriceType}`)} ${periodInfo.periodText(false, true)}`

  const getAdditionalPriceInformation = (): ReactNode[] => {
    if (isEmpty(priceInfo)) {
      return []
    }

    const additionalInformations: ReactNode[] = []

    if (priceInfo.hasRenewalPriceText && isEmpty(customRenewPriceTexts)) {
      additionalInformations.push(
        `${_t("prices.renewalPrefix")} ${renewPrice} ${_t(
          `periodName.renewPrice.${periodInfo?.getPeriodType()}`,
          {
            smart_count: periodInfo?.getPeriodLength(),
          }
        )}`
      )
    }

    if (priceInfo.hasRenewalPriceText && !isEmpty(customRenewPriceTexts)) {
      additionalInformations.push(...customRenewPriceTexts)
    }

    if (!isEmpty(priceInfo?.labels)) {
      const labels: ReactNode[] =
        priceInfo?.labels.map(
          (label): ReactNode => (
            <FlexContainerOrg alignItems="center">
              {label.flatData.text}

              {!isEmpty(label.flatData.tooltip) ? (
                <PushOrg leftSpace={0.5} hasNoReset>
                  <TooltipMol iconSize="small" zIndex={zIndex.FloatingAside}>
                    {label.flatData.tooltip}
                  </TooltipMol>
                </PushOrg>
              ) : null}
            </FlexContainerOrg>
          )
        ) || []

      additionalInformations.push(...labels)
    }

    return additionalInformations
  }

  const priceMonthlyElement = !isEmpty(priceMonthly) ? (
    <>
      {_t("prices.monthlyPricePrefix")}{" "}
      <TextAtm hasFitLineHeight isBold>
        {`${priceMonthly} ${_t("prices.monthlyPricePeriod")}`}
      </TextAtm>
    </>
  ) : null

  return (
    <PriceMol
      variant={variant}
      mainPrice={{
        currentPrice: priceValue,
        crossedPrice: hasPromotion ? oldPriceValue : undefined,
        periodText: priceSuffix,
      }}
      badgeText={
        priceInfo?.hasMonthlyPriceText
          ? priceInfo?.customMonthlyPriceText || priceMonthlyElement
          : null
      }
      additionalInformation={getAdditionalPriceInformation()}
      regularPrice={
        hasPromotion
          ? {
              text: _t("prices.regularPriceWithType", {
                priceType: _t(`prices.${currentPriceType}`),
              }),
              price: oldPriceValue,
              percent: promotionPercent,
            }
          : undefined
      }
      lowestPrice={
        hasPromotion
          ? {
              text: _t("prices.lowestPriceWithType", {
                priceType: _t(`prices.${currentPriceType}`),
              }),
              price: lowestPrice,
              percent: lowestPricePercent,
            }
          : undefined
      }
    />
  )
}

export default DetailedPrice
