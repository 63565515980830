import type { ReactElement } from "react"
import { useContext } from "react"
import React from "react"
import TableMol from "@onestore/hel/dist/components/molecules/TableMol"
import type { TableMolProps } from "@onestore/hel/dist/components/molecules/TableMol"
import HeaderContext from "~/context/HeaderContext"
import useHeaderHeightSpace from "~/hooks/useHeaderHeightSpace"
import { sendContentShowEvent } from "~/lib/ga4"

export default function TableMolWrapper({
  showMoreDetails,
  ...tableProps
}: TableMolProps): ReactElement {
  const headerHeightSpace = useHeaderHeightSpace()
  const { hasHeaderBottomBar } = useContext(HeaderContext)

  const showMoreDetailsWithOnClick = {
    ...showMoreDetails,
    onClick: sendContentShowEvent,
  }

  return (
    <TableMol
      showMoreDetails={showMoreDetailsWithOnClick}
      pushTopSpace={headerHeightSpace}
      hasHeaderBottomBar={hasHeaderBottomBar}
      {...tableProps}
    />
  )
}
